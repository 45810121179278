/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-08-20 11:08:36
 * @LastEditors: ------
 * @LastEditTime: 2021-09-07 14:19:07
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jobNum: 0, //关于我们
    serveProject: 0, //服务项目
    parent: 0, //加入我们
  },
  mutations: {
    setjobNum(state, value) {
      state.jobNum = value;
    },
    setserveProject(state, value) {
      state.serveProject = value;
    },
    setPartner(state, value) {
      state.parent = value;
    },
  },
  actions: {},
  modules: {},
});
