<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-18 13:58:22
 * @LastEditors: ------
 * @LastEditTime: 2021-11-03 10:00:44
-->
<template>
  <div id="app">
    <router-view keep-alive />
  </div>
</template>

<script>
export default {
  created() {
    if (!this._isMobile()) {
      // alert("pc端");
      window.location.href = "https://www.aijiehu.cn";
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="less">
// *{
//   -webkit-filter : grayscale(100%);
// -moz-filter: grayscale(100%);
// -o-filter: grayscale(100%);
// filter: grayscale(100%);
// filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
// }
</style>
