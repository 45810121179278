<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-09-24 15:58:57
 * @LastEditors: ------
 * @LastEditTime: 2021-09-24 16:06:11
-->
<template>
  <div>
    <van-empty description="没有找到此页面">
      <van-button round type="" class="bottom-button" @click="$router.push('/')"
        >返回首页</van-button
      >
    </van-empty>
  </div>
</template>

<script>
export default {
  name: "",
};
</script>

<style scoped lang="less">
div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  .bottom-button {
    width: 160px;
    height: 40px;
  }
}
</style>
