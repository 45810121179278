/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-08-18 13:58:22
 * @LastEditors: ------
 * @LastEditTime: 2021-10-26 13:33:34
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/index.css"; // 导入自定义样式
// import "vant/lib/index.css"; //导入vant 样式
import "amfe-flexible"; //使用 lib-flexible 动态设置 REM 基准值（html 标签的字体大小）

Vue.config.productionTip = false;

import FastClick from "fastclick"; //移动端click 300ms 延迟

FastClick.prototype.focus = function(targetElement) {
    let length;
    if (
        targetElement.setSelectionRange &&
        targetElement.prototype.indexOf("date") !== 0 &&
        targetElement.type !== "time" &&
        targetElement.type !== "month"
    ) {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};
FastClick.attach(document.body); //挂载到全局

import {
    Button,
    NavBar,
    CountDown,
    Overlay,
    Popover,
    Popup,
    Cell,
    Swipe,
    SwipeItem,
    Lazyload,
    Pagination,
    Tab,
    Tabs,
    Empty,
    Dialog,
} from "vant";
Vue.use(Button)
    .use(NavBar)
    .use(CountDown)
    .use(Overlay)
    .use(Popup)
    .use(Cell)
    .use(Swipe)
    .use(SwipeItem)
    .use(Lazyload)
    .use(Pagination)
    .use(Tab)
    .use(Tabs)
    .use(Empty)
    .use(Dialog)
    .use(Popover);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");