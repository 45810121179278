/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-08-18 13:58:22
 * @LastEditors: ------
 * @LastEditTime: 2021-10-26 14:25:13
 */
import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/empty.vue";

Vue.use(VueRouter);
//获取原型对象上的push函数  避免出现同一页跳转报错问题
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    { path: "*", component: NotFound },
    {
        path: "/",
        component: () =>
            import ("../views/Layout/"),

        children: [{
                path: "",
                name: "home",
                component: () =>
                    import ("../views/Home/"),
            },
            {
                path: "/aboutUs",
                name: "aboutUS",
                component: () =>
                    import ("../views/AboutUs/"),
            },
            {
                path: "/partner",
                name: "partner",
                component: () =>
                    import ("../views/Partner/"),
            },
            {
                path: "/newscenter",
                name: "newscenter",
                component: () =>
                    import ("../views/Newscenter/"),
            },
            {
                path: "/newsdetail",
                name: "newsdetail",
                component: () =>
                    import ("../views/Newscenter/Newsdetail.vue"),
            },
            {
                path: "/site",
                name: "site",
                component: () =>
                    import ("../views/Site/"),
            },
            {
                path: "/serveproject",
                name: "serveproject",
                component: () =>
                    import ("../views/ServeProject/"),
            },
            {
                path: "/consult",
                name: "consult",
                component: () =>
                    import ("../views/Consult/"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});
router.afterEach(() => {
    window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //   document.title = to.meta.title;
    // }
    document.title = "上海爱介护社区养老服务有限公司";
    next();
});
export default router;